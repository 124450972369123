<template>
  <modal
    v-if="showModal"
    :show="showModal"
    modal-classes="modal-secondary"
    size="lg"
  >
    <form class="add-form pb-2">
      <div class="form-wrapper">
        <base-input
          type="number"
          :label="`${$t('AUCTIONS.AMOUNT')} (*)`"
          :placeholder="$t('AUCTIONS.AMOUNT')"
          v-model="propertyFee.amount"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.amount" />
      </div>

      <div class="form-wrapper">
        <base-input
          type="number"
          :label="`${$t('AUCTIONS.PERCENT')}`"
          :placeholder="$t('AUCTIONS.PERCENT')"
          v-model="propertyFee.percent"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.percent" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('AUCTIONS.REPAIR_BY_NUMBER')} (*)`"
          :placeholder="$t('AUCTIONS.REPAIR_BY_NUMBER')"
        >
          <el-select
            :clearable="true"
            class="select-primary pagination-select"
            :placeholder="$t('AUCTIONS.REPAIR_BY_NUMBER')"
            v-model="propertyFee.amount_repair_by_number"
            @change="onFormChanged"
          >
            <el-option
              class="select-primary"
              :label="$t(`COMMON.YES`)"
              :value="true"
            >
            </el-option>
            <el-option
              class="select-primary"
              :label="$t(`COMMON.NO`)"
              :value="false"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.amount_repair_by_number" />
      </div>
      <div class="form-wrapper full">
        <!-- Excerpt -->
        <base-input
          :label="`${$t('COMMON.EXCERPT')}`"
          :placeholder="$t('COMMON.EXCERPT')"
        >
          <html-editor v-model="propertyFee.description" @change="onFormChanged()">
          </html-editor>
        </base-input>
        <validation-error :errors="apiValidationErrors.description" />
      </div>
    </form>

    <template slot="footer">
      <button
        v-if="!propertyFee.id"
        type="submit"
        class="btn btn-primary"
        @click="submitApplyFeeForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.SUBMIT") }}
      </button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closePropertyFeeModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import { Button } from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { mapGetters } from "vuex";
import { Option, Select } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

export default {
  name: "auction-apply-fee",

  components: {
    ValidationError,
    HtmlEditor,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["auction", "propertyFee", "showModal", "loading", "formErrors"],

  data() {
    return {
      auctionFees: [],
      lastFee: {},
      showApplyFeeModal: this.showModal,
    };
  },

  computed: {
    ...mapGetters("propertyFees", ["list"]),
  },

  methods: {
    onFormChanged() {
      this.$emit("formChanged");
    },
    closePropertyFeeModal() {
      this.$emit("onCloseApplyFeeModal", true);
    },

    submitApplyFeeForm() {
      const PropertyFeeData = cloneDeep(this.propertyFee);
      this.$emit("onSubmitApplyFeeForm", PropertyFeeData);
    },

    deleteAuctionFee(auctionFeeId) {
      this.$emit("onDeleteAuctionFee", auctionFeeId);
    },
  },

  mounted() {},

  watch: {
    list: {
      handler(newList) {
        this.auctionFees = newList;
        this.resetApiValidation();
        if (newList.length > 0) {
          this.lastFee = this.auctionFees[0];
          this.$emit("updateAuctionFeeModel", newList[0]);
        }
      },
      immediate: true,
    },

    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
