export const ORDER_STATUS_DRAFT = "DRAFT";
export const ORDER_STATUS_CANCELED = "CANCELED";
export const ORDER_STATUS_VALIDATED = "VALIDATED";
export const ORDER_STATUS_COMPLETED = "COMPLETED";

export const DELIVERY_STATUS_PENDING = "PENDING";
export const DELIVERY_STATUS_PARTIALLY_DELIVERED = "PARTIALLY_DELIVERED";
export const DELIVERY_STATUS_DELIVERED = "DELIVERED";

export const INVOICING_STATUS_PENDING = "PENDING";
export const INVOICING_STATUS_PARTIALLY_INVOICED = "PARTIALLY_INVOICED";
export const INVOICING_STATUS_INVOICED = "INVOICED";

export const INVOICING_TYPE_PRODUCTS = "PRODUCTS";
export const INVOICING_TYPE_AMOUNT = "AMOUNT";

export const orderStatusesOption = [
  ORDER_STATUS_DRAFT,
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_VALIDATED,
  ORDER_STATUS_COMPLETED,
];

export const orderInvoicingStatusesOption = [
  INVOICING_STATUS_PENDING,
  INVOICING_STATUS_PARTIALLY_INVOICED,
  INVOICING_STATUS_INVOICED,
];

export const orderDeliveryStatusesOption = [
  DELIVERY_STATUS_PENDING,
  DELIVERY_STATUS_PARTIALLY_DELIVERED,
  DELIVERY_STATUS_DELIVERED,
];
