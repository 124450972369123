var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-select',{class:[_vm.inputClasses],staticStyle:{"text-transform":"uppercase"},attrs:{"name":_vm.$t('COMMON.STATES'),"multiple":_vm.multiple,"filterable":_vm.filterable},on:{"change":_vm.stateChanged},model:{value:(_vm.stateModel),callback:function ($$v) {_vm.stateModel=$$v},expression:"stateModel"}},_vm._l((_vm.states),function(state){return _c('el-option',{key:state[0],class:[_vm.inputClasses],attrs:{"value":state[0].toString().toLowerCase() !== 'québec' &&
      state[0].toString().toLowerCase() !== 'quebec'
        ? state[0]
        : 'Quebec',"label":state[0].toString().toLowerCase() !== 'québec' &&
      state[0].toString().toLowerCase() !== 'quebec'
        ? state[0]
        : _vm.$t('COMMON.QUEBEC')}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }