export default {
  type: "property-fees",
  amount: null,
  percent: null,
  description: "",
  amount_repair_by_number: false,
  relationshipNames: ["property"],
  property: {
    type: "properties",
    id: null,
  },
};
