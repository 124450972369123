export default {
  type: "bid-steps",
  amount_min: null,
  amount_max: null,
  bid_amount: null,
  relationshipNames: ["auction"],
  auction: {
    type: "auctions",
    id: null,
  },
};
