export default {
  type: "auction-fees",
  amount_min: null,
  amount_max: null,
  mrc_fee: null,
  relationshipNames: ["auction"],
  auction: {
    type: "auctions",
    id: null,
  },
};
