<template>
  <modal
    v-if="showModal"
    :show="showModal"
    modal-classes="modal-secondary"
    size="lg"
  >
    <form class="add-form pb-2" @submit.prevent="">
      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.MINIMUM_AMOUNT')} (*)`"
          :placeholder="$t('COMMON.MINIMUM_AMOUNT')"
          v-model="bidStep.amount_min"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.amount_min" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.MAXIMUM_AMOUNT')}`"
          :placeholder="$t('COMMON.MAXIMUM_AMOUNT')"
          v-model="bidStep.amount_max"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.amount_max" />
      </div>

      <div class="form-wrapper w-100">
        <base-input
          :label="`${$t('COMMON.BID_AMOUNT')} (*)`"
          :placeholder="$t('COMMON.BID_AMOUNT')"
          v-model="bidStep.bid_amount"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.bid_amount" />
      </div>
    </form>

    <template slot="footer">
      <button
        v-if="!bidStep.id"
        type="submit"
        class="btn btn-primary"
        @click="submitBidStepForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.ADD_ITEM") }}
      </button>

      <base-button
        v-if="bidStep.id"
        native-type="submit"
        type="primary"
        class="new-purchasesOrder-item--add"
        @click="submitBidStepForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.UPDATE_ITEM") }}
      </base-button>

      <base-button
        v-if="bidStep.id"
        type="danger"
        class="ml-3"
        @click="deleteBidStep(bidStep.id)"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.DELETE_ITEM") }}
      </base-button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeBidStepModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import { Button } from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { mapGetters } from "vuex";

export default {
  name: "bid-step-form",

  components: {
    ValidationError,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["auction", "bidStep", "showModal", "loading", "formErrors"],

  data() {
    return {
      bidSteps: [],
      lastStep: {},
    };
  },

  computed: {
    ...mapGetters("bidSteps", ["list"]),
  },

  methods: {
    closeBidStepModal() {
      this.$emit("onCloseBidStepModal", true);
    },

    submitBidStepForm() {
      const bidStepData = cloneDeep(this.bidStep);
      bidStepData.amount_min = parseFloat(bidStepData.amount_min);
      bidStepData.amount_max = parseFloat(bidStepData.amount_max);
      bidStepData.bid_amount = parseFloat(bidStepData.bid_amount);
      bidStepData.auction = this.auction;

      this.$emit("onSubmitBidStepForm", bidStepData);
    },

    deleteBidStep(bidStepId) {
      this.$emit("onDeleteBidStep", bidStepId);
    },
  },

  mounted() {},

  watch: {
    list: {
      handler(newList) {
        this.bidSteps = newList;
        this.resetApiValidation();
        if (newList.length > 0) {
          this.lastStep = this.bidSteps[0];
          this.$emit("updateBidStepModel", newList[0]);
        }
      },
      immediate: true,
    },

    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
