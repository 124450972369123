import {FEES_TYPE_NORMAL} from "@/constants/auctions";

export default {
  type: "other-fees",
  amount: null,
  name: null,
  fees_type: FEES_TYPE_NORMAL,
  excerpt: null,
  percent: null,
  mrc_fee: null,
  relationshipNames: ["auction"],
  auction: {
    type: "auctions",
    id: null,
  },
};
