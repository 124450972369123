<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="auctionFees"
        >
          <el-table-column :label="$t('COMMON.MINIMUM_AMOUNT')" min-width="30">
            <template v-slot="{ row }">
              {{ $formatCurrency(row.amount_min) }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('COMMON.MAXIMUM_AMOUNT')" min-width="30">
            <template v-slot="{ row }">
              <span v-if="row.amount_max">{{
                $formatCurrency(row.amount_max)
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('COMMON.MRC_FEES')" min-width="30">
            <template v-slot="{ row }">
              {{ $formatCurrency(row.mrc_fee) }}
            </template>
          </el-table-column>

          <el-table-column min-width="10">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip :content="$t('COMMON.EDIT')" placement="top">
                <a
                  type="text"
                  @click="openAuctionFeeModal(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Tooltip, Button } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { ORDER_STATUS_DRAFT } from "@/constants/orders";

export default {
  name: "auction-fee-list-table",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: {
    filterAuctionId: {
      type: String,
      default: null,
      description: "Auction id",
    },
  },

  data() {
    return {
      auctionFees: [],
    };
  },

  computed: {},

  methods: {
    openAuctionFeeModal(auctionFee) {
      this.$emit("onOpenAuctionFeeModal", auctionFee);
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
        };

        if (this.filterAuctionId) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              auctionId: this.filterAuctionId,
            },
          };
        }
        await this.$store.dispatch("auctionFees/list", params);
        this.auctionFees = this.$store.getters["auctionFees/list"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },

  mounted() {
    this.$parent.$on("auctionFeesUpdated", this.getListDebounced);
  },

  beforeDestroy() {
    this.$parent.$off("auctionFeesUpdated", this.getListDebounced);
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
  },
};
</script>

<!-- <style>
.purchasesOrder-item-excerpt p,
.purchasesOrder-item-excerpt,
.purchasesOrder-item-excerpt * {
  font-size: 0.7rem;
}
</style> -->
