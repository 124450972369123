<template>
  <div class="elite-tabs-wrapper-content">
    <div class="custom-header">
      <h3>{{ $t("COMMON.BID_STEPS") }}</h3>
      <base-button
        @click="openBidStepModal()"
        type="button"
        class="elite-button add"
      >
        <span class="btn-inner--icon">
          <i class="far fa-plus-circle"></i>
        </span>
        <span class="btn-inner--text">
          {{ $t("AUCTIONS.ADD_BID_STEP") }}
        </span>
      </base-button>
    </div>
    <bid-step-list-table
      :filterAuctionId="auction.id"
      @onOpenBidStepModal="openBidStepModal"
    />

    <bid-step-form
      :auction="auction"
      :bidStep="bidStep"
      :showModal="showModal"
      :loading="loading"
      :formErrors="itemsFormError"
      @updateBidStepModel="updateBidStepModel"
      @onCloseBidStepModal="closeBidStepModal"
      @onSubmitBidStepForm="handleBidStepFormSubmit"
      @onDeleteBidStep="deleteBidStep"
    />
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import BidStepListTable from "./BidStepListTable.vue";
import defaultBidStep from "../defaultBidStep";
import BidStepForm from "./BidStepForm.vue";

export default {
  name: "auction-view-bid-steps",

  components: { BidStepListTable, BidStepForm },

  props: ["auction"],

  mixins: [],

  data() {
    return {
      bidStep: cloneDeep(defaultBidStep),
      loading: false,
      showModal: false,
      itemsFormError: null,
      amountMin: 0,
    };
  },

  computed: {},

  methods: {
    openBidStepModal(bidStep = null) {
      if (!bidStep) {
        this.bidStep = cloneDeep(defaultBidStep);
        this.bidStep.amount_min = !isNaN(this.amountMin)
          ? this.amountMin
          : this.bidStep.amount_min;
      } else {
        this.bidStep = cloneDeep(bidStep);
      }
      this.showModal = true;
    },

    closeBidStepModal() {
      this.showModal = false;
      this.loading = false;
    },

    handleBidStepFormSubmit(bidStepData) {
      if (!bidStepData.id) {
        this.addBidStep(bidStepData);
      } else {
        this.editBidStep(bidStepData);
      }
    },

    async addBidStep(bidStepData) {
      this.loading = true;
      try {
        bidStepData.auction.id = this.auction.id;
        await this.$store.dispatch("bidSteps/add", bidStepData);
        this.$emit("bidStepsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("COMMON.ITEM_ADDED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.itemsFormError[0].detail,
        });
      }
    },

    async editBidStep(bidStepData) {
      this.loading = true;
      try {
        await this.$store.dispatch("bidSteps/update", bidStepData);
        this.$emit("bidStepsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("COMMON.ITEM_EDITED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.itemsFormError = error.response.data.errors;
        this.$notify({
          type: "danger",
          message: this.itemsFormError[0].detail,
        });
        this.loading = false;
      }
    },

    async deleteBidStep(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("bidSteps/destroy", id);
        this.$emit("bidStepsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("COMMON.ITEM_DELETED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    updateBidStepModel(lastStep) {
      if (lastStep) {
        this.amountMin = parseFloat(lastStep.amount_max) + 1;
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
