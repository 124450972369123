<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("AUCTIONS.AUCTIONS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openAuctionCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_AUCTIONS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("AUCTIONS.ADD_AUCTION")
              }}</span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_AUCTIONS)"
              :objectType="'auctions'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <auction-list-table
        @onViewAuction="openAuctionViewModal"
        @onEditAuction="openAuctionEditModal"
        @onDeleteAuction="deleteAuction"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewAuctionModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewAuctionModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'AUCTION_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("AUCTIONS.VIEW_AUCTION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openAuction"
                :objectType="'auctions'"
                :objectId="openAuction.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openAuctionEditModal(openAuction)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="edit" @click="deleteAuction(openAuction)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeAuctionModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-auction-page
              @auctionChange="onAuctionChange"
              v-if="openAuction"
              :auctionId="openAuction.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditAuctionModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditAuctionModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'WAREHOUSE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("AUCTIONS.EDIT_AUCTION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openAuctionViewModal(openAuction)">
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeAuctionModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-auction-page
              v-if="openAuction"
              :auctionId="openAuction.id"
              @onViewAuction="openAuctionViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddAuctionModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddAuctionModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'WAREHOUSE'">
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("AUCTIONS.ADD_AUCTION") }}
            </h1>
            <button class="close" @click="closeAuctionModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-auction-page @onViewAuction="openAuctionViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import AuctionListTable from "./partials/AuctionListTable.vue";
import EditAuctionPage from "./components/EditAuctionComponent.vue";
import AddAuctionPage from "./components/AddAuctionComponent.vue";
import ViewAuctionPage from "./components/ViewAuctionComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    AuctionListTable,
    NotificationSubscription,
    EditAuctionPage,
    AddAuctionPage,
    ViewAuctionPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewAuctionModalOpened = false;
    let isEditAuctionModalOpened = false;
    let isAddAuctionModalOpened = false;
    let openAuction = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewAuctionModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditAuctionModalOpened = true;
      }
      openAuction = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddAuctionModalOpened = true;
    }
    return {
      isViewAuctionModalOpened: isViewAuctionModalOpened,
      isEditAuctionModalOpened: isEditAuctionModalOpened,
      isAddAuctionModalOpened: isAddAuctionModalOpened,
      openAuction: openAuction,
      renderKey: 1,
    };
  },

  methods: {
    openAuctionCreateModal() {
      this.closeAuctionModal();
      this.isAddAuctionModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Auctions",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    onAuctionChange(auction) {
      this.openAuction = auction;
    },
    openAuctionViewModal(auction, reRender = false) {
      this.closeAuctionModal();
      this.openAuction = auction;
      this.isViewAuctionModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Auctions",
          query: { id: this.openAuction.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openAuctionEditModal(auction) {
      this.closeAuctionModal();
      this.openAuction = auction;
      this.isEditAuctionModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Auctions",
          query: { id: this.openAuction.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeAuctionModal() {
      this.isAddAuctionModalOpened = false;
      this.isViewAuctionModalOpened = false;
      this.isEditAuctionModalOpened = false;
      this.openAuction = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Auctions",
          query: {},
        }).href
      );
    },

    async deleteAuction(auction) {
      const confirmation = await swal.fire({
        title: this.$t("AUCTIONS.DELETE_THIS_AUCTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("auctions/destroy", auction.id);
          this.renderKey++;
          this.closeAuctionModal();
          this.$notify({
            type: "success",
            message: this.$t("AUCTIONS.AUCTION_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
