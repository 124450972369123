<template>
  <div class="row">
    <div class="custom-header mt-4">
      <h3>{{ $t("COMMON.OTHER_FEES") }}</h3>
      <div class="">
        <base-button
            v-if="$currentUserCan($permissions.PERM_CREATE_OTHER_FEES)"
            @click="onToggleFeeModalState()"
            type="button"
            class="elite-button add"
        >
        <span class="btn-inner--icon">
          <i class="far fa-plus-circle"></i>
        </span>
          <span class="btn-inner--text">
          {{ $t("AUCTIONS.ADD_FEES") }}
        </span>
        </base-button>
      </div>
    </div>

    <div class="row col-12">
      <auction-other-fee-list-table
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_OTHER_FEES)"
          :auction="auction"
          :showModal="showModal"
          @onToggleAuctionFeeModal="onToggleFeeModalState"
      />
    </div>

  </div>

</template>
<script>
import {cloneDeep} from "lodash";
import defaultAuctionFee from "../defaultAuctionFee";
import AuctionOtherFeeListTable from "./AuctionOtherFeeListTable";

export default {
  name: "auction-other-fees",

  components: {
    AuctionOtherFeeListTable
  },

  props: ["auction"],

  mixins: [],

  data() {
    return {
      auctionFee: cloneDeep(defaultAuctionFee),
      loading: false,
      showModal: false,
      itemsFormError: null,
      amountMin: 0,
    };
  },

  computed: {},

  methods: {
    onToggleFeeModalState() {
      this.showModal = !this.showModal;
    },

    closeAuctionFeeModal() {
      this.onToggleFeeModalState();
      this.loading = false;
    },
  },

  mounted() {
  },

  watch: {},
};
</script>
