<template>
  <modal
    :show="showModal"
    modal-classes="modal-secondary"
    size="lg"
  >
    <form class="add-form pb-2" @submit.prevent="">
      <div class="form-wrapper half">
        <base-input
          :label="`${$t('AUCTIONS.FEES_NAME')} (*)`"
          :placeholder="$t('AUCTIONS.FEES_NAME')"
          v-model="feeModel.name"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.name" />
      </div>

      <div class="form-wrapper half">
        <base-input
          :label="`${$t('AUCTIONS.FEES_TYPE')} (*)`"
          :placeholder="$t('AUCTIONS.FEES_TYPE')"
        >
          <el-select
              :required="true"
              :clearable="true"
              class="select-primary pagination-select"
              :placeholder="$t('AUCTIONS.FEES_TYPE')"
              v-model="feeModel.fees_type"
          >
            <el-option
                class="select-primary"
                v-for="(item, key) in FEES_TYPE_LIST"
                :key="key"
                :label="$t(`AUCTIONS.FEES_TYPE_${item}`)"
                :value="item"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.fees_type" />
      </div>

      <div class="form-wrapper half">
        <base-input
            type="number"
          :label="`${$t('AUCTIONS.AMOUNT')} (*)`"
          :placeholder="$t('AUCTIONS.AMOUNT')"
          v-model="feeModel.amount"
            @change="onAmountChanged"
            @keypress="onAmountChanged"
            @keydown="onAmountChanged"
            @keyup="onAmountChanged"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.amount" />
      </div>

      <div class="form-wrapper half">
        <base-input
          :label="`${$t('AUCTIONS.PERCENT')} (*)`"
          :placeholder="$t('AUCTIONS.PERCENT')"
          v-model="feeModel.percent"
          type="number"
          @change="onPercentChanged"
          @keypress="onPercentChanged"
          @keydown="onPercentChanged"
          @keyup="onPercentChanged"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.percent" />
      </div>

      <div class="form-wrapper full">
        <base-input
          :label="`${$t('COMMON.EXCERPT')} (*)`"
          :placeholder="$t('COMMON.EXCERPT')"
        >
          <textarea
              class="form-control"
              v-model="feeModel.excerpt"
              cols="30" rows="5"></textarea>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>
    </form>

    <template slot="footer">
      <button
        v-if="!feeModel.id"
        type="submit"
        class="btn btn-primary"
        @click="submitAuctionOtherFeeForm"
        :disabled="submitting"
      >
        <span v-if="submitting" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.ADD_ITEM") }}
      </button>

      <base-button
        v-if="feeModel.id"
        native-type="submit"
        type="primary"
        class="new-purchasesOrder-item--add"
        @click="submitAuctionOtherFeeForm"
        :disabled="submitting"
      >
        <span v-if="submitting" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.UPDATE_ITEM") }}
      </base-button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeAuctionFeeModal"
        :disabled="submitting"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {Button, Option, Select} from "element-ui";
import {cloneDeep} from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";

import {FEES_TYPE_LIST} from '@/constants/auctions';
import defaultAuctionOtherFee from "../defaultAuctionOtherFee";

export default {
  name: "auction-fee-form",

  components: {
    ValidationError,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["auction", "showModal", "fee", "loading", "formData", "reloadData"],

  data() {
    return {
      fees: [],
      submitting: false,
      feeModel: {...defaultAuctionOtherFee},
      FEES_TYPE_LIST,
    };
  },

  computed: {
  },

  methods: {
    closeAuctionFeeModal() {
      this.setApiValidation([]);
      this.$emit("onToggleAuctionFeeModal");
    },

    submitAuctionOtherFeeForm() {
      const feeData = cloneDeep(this.feeModel);
      feeData.auction = this.auction;
      if (feeData?.id) {
        this.updateAuctionOtherFee(feeData)
      } else {
        this.addAuctionOtherFee(feeData)
      }
    },

    onAmountChanged() {
      this.feeModel.percent = null;
    },

    onPercentChanged() {
      this.feeModel.amount = null;
    },

    async addAuctionOtherFee(auctionFeeData) {
      this.submitting = true;
      try {
        auctionFeeData.auction.id = this.auction.id;
        await this.$store.dispatch("auctionOtherFees/add", auctionFeeData);
        this.$emit("auctionOtherFeesUpdated", null);
        this.$notify({
          type: "success",
          message: this.$t("COMMON.ITEM_ADDED"),
        });
        this.submitting = false;
        if (this.reloadData) {
          this.reloadData()
        }
        this.feeModel = defaultAuctionOtherFee;
        this.closeAuctionFeeModal()
      } catch (error) {
        this.setApiValidation(error?.response?.data?.errors);
        this.submitting = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async updateAuctionOtherFee(auctionFeeData) {
      this.submitting = true;
      try {
        await this.$store.dispatch("auctionOtherFees/update", auctionFeeData);
        this.$notify({
          type: "success",
          message: this.$t("COMMON.ITEM_EDITED"),
        });
        this.submitting = false;
        if (this.reloadData) {
          this.reloadData()
        }
        this.feeModel = {
          ...defaultAuctionOtherFee,
        };
        this.closeAuctionFeeModal()
      } catch (error) {
        this.setApiValidation(error?.response?.data?.errors);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.submitting = false;
      }
    },
  },

  mounted() {},

  watch: {
    formData(value) {
      this.feeModel = {
        ...value,
      };
    },
  },
};
</script>
